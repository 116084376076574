<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="light"
      variant="light"
      style="
        z-index: 2;
        background: white !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      "
    >
      <div @click.stop="drawer = !drawer" class="mr-3" style="cursor: pointer">
        <v-icon> menu </v-icon>
      </div>
      <img src="assets/img/LogoNavBar.png" class="mr-2" width="30px" />
      <b-navbar-brand
        :to="{ path: '/' }"
        style="color: #a1000c; font-weight: bold"
        >Cockpit</b-navbar-brand
      >
      <p
        class="mx-5 pt-2 text-decoration-none"
        style="
          overflow: hidden;
          width: 100%;
          white-space: nowrap;
          color: black !important;
        "
      >
        <a
          href="https://universo.maq-larem.com.br:7777/"
          target="__blank"
          style="text-decoration: none; color: black"
          ><strong class="mr-2" style="color: #a1000c">Avisos:</strong
          >{{ avisos.text }}</a
        >
      </p>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <div
          class="
            nav-item
            grey
            mx-2
            d-lg-block
            text-big
            font-weight-light
            line-height-1
            opacity-25
          "
        ></div>

        <b-navbar-nav>
          <b-nav-item-dropdown right>
            <template slot="button-content" class="menu">
              <span
                class="
                  d-inline-flex
                  flex-lg-row-reverse
                  align-items-center align-middle
                "
              >
                <span class="" style="width: 140px">
                  <div v-if="user !== null">
                    {{ user.first_name }}
                  </div>
                </span>
              </span>
            </template>
            <b-dropdown-item @click="getId(user.id)">
              <div class="d-flex">
                <span class="material-icons-outlined mr-2">
                  account_circle
                </span>
                <span class="black">Meu Perfil</span>
              </div>
            </b-dropdown-item>
            <hr class="mt-0" />

            <b-dropdown-item :to="{ path: '/library' }">
              <div class="d-flex">
                <span class="material-icons-outlined mr-2"> keyboard </span>
                <span class="black">Gerenciar aplicativos</span>
              </div>
            </b-dropdown-item>

            <b-dropdown-item :to="{ path: '/users' }">
              <div class="d-flex">
                <span class="material-icons-outlined mr-2"> groups </span>
                <span class="black">Usuários</span>
              </div>
            </b-dropdown-item>

            <div class="py-1 px-4">
              <a
                href="https://universo.maq-larem.com.br:7777/ramais/"
                target="_blank"
                style="color: #212529"
                class="d-flex text-decoration-none"
              >
                <span class="material-icons-outlined mr-2"> headset_mic </span>
                <span class="black">Ramais</span>
              </a>
            </div>

            <b-dropdown-item :to="{ path: '/bis' }">
              <div class="d-flex">
                <span class="material-icons-outlined mr-2"> groups </span>
                <span class="black">Painel BI</span>
              </div>
            </b-dropdown-item>

            <b-dropdown-item @click="logout">
              <div class="d-flex">
                <span class="material-icons-outlined mr-2"> logout </span>
                <span class="black">Sair</span>
              </div>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <div
            @click.stop="drawer2 = !drawer2"
            class="ml-3 mt-2"
            style="cursor: pointer"
          >
            <v-icon> menu </v-icon>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="d-flex">
      <v-navigation-drawer :mini-variant.sync="drawer" class="pb-auto">
        <NavItem :user="user" :key="user.id" />
      </v-navigation-drawer>
      <div style="width: 100vw !important" class="mx-3">
        <router-view></router-view>
      </div>
      <v-navigation-drawer id="right" v-model="drawer2" right>
        <NavCard class="mt-2 mb-2" />
      </v-navigation-drawer>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~/public/assets/styles/scss/components/navbar.scss";
.v-navigation-drawer--close {
  display: none;
}
</style>

<style scoped>
.v-navigation-drawer--right >>> .v-navigation-drawer__border {
  display: none;
}
aside:not(.v-navigation-drawer--mini-variant) {
  width: 30% !important;
  min-height: 400px;
}
.v-navigation-drawer--mini-variant {
  width: 80px !important;
}
</style>

<script>
import AuthService from "../../services/AuthService/AuthService";
import UserService from "../../services/UserService/UserService";
import NavItem from "../Cards/nav_item.vue";
import NavCard from "../Cards/nav_card.vue";
import axios from "axios";
import Constants from "@/core/constants/Constants";

export default {
  name: "NavBar",
  components: { NavItem, NavCard },
  data() {
    return {
      user: {
        id: null,
      },
      workspace: null,
      workspaces: [],
      drawer: false,
      drawer2: true,
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
      avisos: {
        title: "Sem avisos",
        text: "Intranet não possui nenhum aviso no momento.",
      },
    };
  },
  mounted: function () {
    this.getMe();
    axios
      .get(`${Constants.BASE_URL}/api/intranet_requests/`)
      .then((response) => {
        this.avisos =
          response["data"]["feeds"][response["data"]["feeds"].length - 1] ??
          this.avisos;
      });
  },

  methods: {
    getMe: async function () {
      this.user = await new UserService().me();
    },

    getId: async function (id) {
      let userId = id;
      if (userId) {
        return this.$router.push({ path: `/profile/${userId}` });
      }
    },

    logout() {
      new AuthService().logout();
    },
  },
};
</script>
