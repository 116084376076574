import axios from "axios";
import Constants from "../../core/constants/Constants";
import AuthService from "../AuthService/AuthService";
import { getCookie } from "../../utils/utils";
import Swal from "sweetalert2";

/*
Areas Service
*/
export default class AreaService {
  INDICADOR_ENDPOINT = "/api/campaigns/";
  BI_ENDPOINT = "/api/bi-panels/";

  async allCampaigns() {
    let hasError = false;
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.INDICADOR_ENDPOINT + "get_by_type", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        hasError = true;
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });

    if (hasError) return;

    if (res && res.status == 200) {
      return res.data;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res.data.message,
      });
    }
  }

  async getCampaignsId(id) {
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.INDICADOR_ENDPOINT + `${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("GET USER ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível encontrar o usuário",
        });
      });

    let resOk =
      "status_code" in res.data
        ? (resOk = res.data["status_code"] === 200)
        : true;
    if (res && res.status == 200 && resOk) {
      return res.data;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Não foi possível encontrar o usuário",
      });
    }
  }

  async createCampaign(app) {
    let hasError = false;
    await new AuthService().refreshToken();
    let formData = new FormData();
    for (let i in app) {
      if (Array.isArray(app[i])) {
        app[i].forEach((item) => {
          formData.append(i, item);
        });
      } else {
        formData.append(i, app[i]);
      }
    }
    await axios
      .post(Constants.BASE_URL + this.INDICADOR_ENDPOINT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        hasError = true;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Preencha todos os campos",
        });
        return err.response.request;
      });

    if (hasError) return;
    window.location.reload(false);
  }
}
