<template>
  <div data-app>
    <NavBar class="mb-5" v-if="$route.meta.requiresNavBar" />
    <router-view v-else></router-view>
  </div>
</template>

<script>
import NavBar from "./components/Nav/NavBar.vue";

import { getCookie } from "./utils/utils";
import { router } from "./routers/router";

export default {
  name: "App",
  components: {
    NavBar,
  },
  created: function () {
    if (!getCookie("access")) {
      return router.push("/login");
    }
  },
};
</script>

<style lang="scss">
@import "/public/assets/styles/bootstrap/_variables.scss";
@import "/public/assets/styles/bootstrap/_bootstrap.scss";
</style>
