import axios from "axios";
import Constants from "../../core/constants/Constants";
import { setCookie, getCookie, eraseCookie } from "../../utils/utils";
import { router } from "../../routers/router";
import Swal from "sweetalert2";

var instance = axios.create({
  // validateStatus: function (status) {
  //     return true || status;
  // }
});

/*
Auth Service
*/
let TOKEN = "";
export default class AuthService {
  PLATAFORMA_URL = "http://dev.plataforma.maq-local.com";
  PLATAFORMA_ENDPOINT = "/api/login/";
  LOGIN_ENDPOINT = "/api/login/";
  REFRESH_ENDPOINT = "/api/refresh-token/";

  async login(auth) {
    let hasError = false;
    const res = await instance
      .post(Constants.BASE_URL + this.LOGIN_ENDPOINT, JSON.stringify(auth), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        hasError = true;
        console.log(err.response);
        // Swal.fire(
        //     {
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: JSON.parse(err.request.response).detail,
        //     }
        // );
        return err.response.request;
      });

    if (hasError) return;

    if (res && res.status == 200) {
      const data = res.data;

      setCookie("access", data["access"], 1400);
      setCookie("refresh", data["refresh"], 1400);
      router.push("/");
    }
  }

  async refreshToken() {
    const res = await axios
      .post(
        Constants.BASE_URL + this.REFRESH_ENDPOINT,
        { refresh: getCookie("refresh") },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((err) => {
        console.log("REFRESH ERROR");
        console.log(err);
      });

    if (res) {
      const data = res.data;
      TOKEN = res.data.access;

      setCookie("access", data["access"], 1400);
    } else {
      this.logout();
    }
  }

  async loginPlataforma() {
    if (TOKEN == "") {
      this.refreshToken();
    }
    let hasError = false;
    const res = await instance
      .post(
        this.PLATAFORMA_URL + this.PLATAFORMA_ENDPOINT,
        JSON.stringify({ access_token: TOKEN }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((err) => {
        hasError = true;
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });

    if (hasError) return;

    if (res && res.status == 200) {
      const data = res.data;

      setCookie("access", data["access"], 1400);
      setCookie("refresh", data["refresh"], 1400);
      window.open(
        "http://dev.plataforma.maq-local.com/?access_token=" +
          TOKEN +
          "&next=321#/",
        "_blank"
      );
    }
  }

  logout() {
    eraseCookie("workspace");
    eraseCookie("access");
    eraseCookie("refresh");
    router.push("/login");
  }
}
