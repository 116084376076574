import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { router } from "./routers/router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vuetify from "@/plugins/vuetify"; // path to vuetify export

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "material-icons/iconfont/material-icons.css";

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
