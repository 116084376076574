import Vue from "vue";
import VueRouter from "vue-router";

let authPage = Vue.component("AuthPage", () =>
  import("../pages/Auth/AuthPage.vue")
);
let homePage = Vue.component("HomePage", () =>
  import("../pages/Home/HomePage.vue")
);
let usersPage = Vue.component("UsersPage", () =>
  import("../pages/Users/UsersPage.vue")
);
let libraryPage = Vue.component("LibraryPage", () =>
  import("../pages/Library/LibraryPage.vue")
);
let profilePage = Vue.component("ProfilePage", () =>
  import("../pages/Profile/ProfilePage.vue")
);
let crudApp = Vue.component("CrudApp", () =>
  import("../pages/App/CrudApp.vue")
);
let biPage = Vue.component("BiPage", () => import("../pages/BI/bi.vue"));
let newBI = Vue.component("NewBI", () => import("../pages/BI/newBI.vue"));

const routes = [
  {
    path: "/login",
    component: authPage,
    meta: { requiresNavBar: false, requiresReportButton: false },
  },
  {
    path: "/",
    component: homePage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/area/:id",
    component: homePage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/home",
    component: homePage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/users",
    component: usersPage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/library",
    component: libraryPage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/profile",
    component: profilePage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/profile/:id",
    component: profilePage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/library/app/:id",
    component: crudApp,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/bis",
    component: biPage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/newBI",
    component: newBI,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/newBI/:id",
    component: newBI,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/indicadores/:id",
    component: homePage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/organograma/:id",
    component: homePage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/campanha/:id",
    component: homePage,
    meta: { requiresNavBar: true, requiresReportButton: true },
  },
  {
    path: "/cadastro",
    component: () => import("../pages/SignUp/SignUpPage.vue"),
    meta: { requiresNavBar: false, requiresReportButton: false },
  },
];

export const router = new VueRouter({
  routes, // short for `routes: routes`
});
