<template>
  <div
    class="background"
    style="
      width: 100%;
      margin: auto;
      margin-top: 15px;
      margin-bottom: 15px;
      overflow-y: scroll;
      height: 75vh;
    "
  >
    <!-- <a href="https://universo.maq-larem.com.br:7777/" target="__blank" style="text-decoration: none">
            <v-card
            elevation="5"
            class="mt-3 ml-2 mr-2"
            shaped
            >
            <div class="pb-3">
                <v-card-title class="pb-2">Avisos</v-card-title>
                <div class="px-3  grey--text text-subtitle-1">
                    {{ avisos.text }}
                </div>
            </div>
            </v-card>
        </a> -->

    <a
      href="https://universo.maq-larem.com.br:7777/"
      target="__blank"
      style="text-decoration: none"
    >
      <v-card elevation="2" class="mt-3 ml-2 mr-2" shaped>
        <div class="pb-3">
          <v-card-title class="pb-2">Destaques</v-card-title>
          <div class="px-3 pb-2 grey--text text-subtitle-1">
            {{ destaques.text }}
          </div>
          <div class="text-center mx-3">
            <img
              width="100%"
              :src="
                destaques.img.replace(
                  'http://universo.maq-larem.com.br/',
                  'https://universo.maq-larem.com.br:7777/'
                )
              "
            />
          </div>
        </div>
      </v-card>
    </a>
    <a
      href="https://universo.maq-larem.com.br:7777/"
      target="__blank"
      style="text-decoration: none"
    >
      <v-card elevation="2" class="mt-3 ml-2 mr-2" shaped>
        <div class="pb-3">
          <v-card-title class="pb-2">Aniversariantes</v-card-title>
          <div
            class="px-3 pb-2 grey--text text-subtitle-1"
            v-for="(pessoas, i) in pessoasAniversariantes"
            :key="i"
          >
            {{ pessoas.first_name }} -
            {{ new Date(pessoas.birthday).getDate() }}/{{
              new Date(pessoas.birthday).getMonth() + 1
            }}
          </div>
        </div>
      </v-card>
    </a>
    <a
      href="https://universo.maq-larem.com.br:7777/"
      target="__blank"
      style="text-decoration: none"
    >
      <v-card elevation="2" class="mt-3 ml-2 mr-2" shaped>
        <div class="pb-3">
          <v-card-title class="pb-2">Lembretes</v-card-title>
          <div class="px-3 grey--text text-subtitle-1">
            {{ lembretes.text }}
          </div>
        </div>
      </v-card>
    </a>
  </div>
</template>

<script>
import axios from "axios";
import Constants from "@/core/constants/Constants";
export default {
  name: "NavItem",
  data() {
    return {
      avisos: {
        title: "Sem avisos",
        text: "Intranet não possui nenhum aviso no momento.",
      },
      destaques: {
        title: "Sem Destaques",
        text: "Intranet não possui nenhuma postagem em destaque no momento.",
      },
      lembretes: {
        title: "Sem Lembretes",
        text: "Intranet não possui nenhum lembrete no momento.",
      },
      aniversariantes: {
        title: "Aniversariantes do mês",
        text: "Clique para ver os aniversariantes do mês!",
      },
      pessoasAniversariantes: [],
    };
  },
  mounted() {
    axios
      .get(`${Constants.BASE_URL}/api/intranet_requests/`)
      .then((response) => {
        this.avisos =
          response["data"]["feeds"][response["data"]["feeds"].length - 1] ??
          this.avisos;

        this.destaques =
          response["data"]["posts"][response["data"]["posts"].length - 1] ??
          this.destaques;

        this.lembretes =
          response["data"]["wordsofpresident"][
            response["data"]["wordsofpresident"].length - 1
          ] ?? this.lembretes;

        this.pessoasAniversariantes = response["data"]["birthdays"] ?? [];
        this.pessoasAniversariantes = this.pessoasAniversariantes
          .filter((p) => p.first_name && p.is_active)
          .map((p) => {
            p.birthday = new Date(`${p.birthday}T00:00:00-03:00`);
            return p;
          });
      });
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400&family=Poppins:wght@100;200;300;500;600;700;800;900&display=swap");
.text-h6 {
  color: #c10015;
}

.icon {
  font-size: 18px;
}

.item {
  margin-left: 10% !important;
}
.v-list-item__content {
  margin-left: 10%;
}

.v-card__title {
  color: #c10015;
}

.item:hover {
  color: #c10015;
  background-color: rgb(245, 223, 223);
}

.text-subtitle-1 {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
</style>
