import axios from "axios";
import Constants from "../../core/constants/Constants";
import AuthService from "../AuthService/AuthService";
import { getCookie } from "../../utils/utils";
import Swal from "sweetalert2";
import { router } from "../../routers/router";
// import { router } from '../../routers/router'

/*
Areas Service
*/
export default class AreaService {
  AREAS_ENDPOINT = "/api/areas/";
  BI_ENDPOINT = "/api/bi-panels/";

  async allAreas() {
    let hasError = false;
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.AREAS_ENDPOINT, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        hasError = true;
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });

    if (hasError) return;

    if (res && res.status == 200) {
      return res.data;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res.data.message,
      });
    }
  }

  async allBis() {
    let hasError = false;
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.BI_ENDPOINT, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        hasError = true;
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });

    if (hasError) return;

    if (res && res.status == 200) {
      return res.data;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: res.data.message,
      });
    }
  }

  async getBiId(id) {
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.BI_ENDPOINT + `${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("GET USER ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível encontrar o usuário",
        });
      });

    let resOk =
      "status_code" in res.data
        ? (resOk = res.data["status_code"] === 200)
        : true;
    if (res && res.status == 200 && resOk) {
      return res.data;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Não foi possível encontrar o usuário",
      });
    }
  }

  async createBI(bi) {
    await new AuthService().refreshToken();
    await axios
      .post(Constants.BASE_URL + this.BI_ENDPOINT, JSON.stringify(bi), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("CREATE USERS ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Todos os campos devem estar preenchidos",
        });
      });

    router.push("/bis");
  }

  async editBI(bi) {
    await new AuthService().refreshToken();
    await axios
      .patch(Constants.BASE_URL + this.BI_ENDPOINT + `${bi.id}/`, bi, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("EDIT USER ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Os campos não podem estar em branco",
        });
      });
  }

  async deleteBI(id) {
    await new AuthService().refreshToken();
    await axios
      .delete(Constants.BASE_URL + this.BI_ENDPOINT + `${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("DELETE USER ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível deletar o usuário",
        });
      });
    window.location.reload(false);
  }
}
