import axios from "axios";
import Constants from "../../core/constants/Constants";
import AuthService from "../AuthService/AuthService";
import { getCookie } from "../../utils/utils";
import { router } from "../../routers/router";
import Swal from "sweetalert2";
// import { router } from '../../routers/router'

/*
User Service
*/
export default class UserService {
  USERS_ENDPOINT = "/api/users/";

  async me() {
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.USERS_ENDPOINT + "me", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("GET ME ACCOUNT ERROR");
        console.log(err);
      });

    let resOk =
      "status_code" in res.data
        ? (resOk = res.data["status_code"] === 200)
        : true;
    if (res && res.status == 200 && resOk) {
      return res.data;
    } else {
      // Swal.fire(
      //     {
      //         icon: 'error',
      //         title: 'Oops...',
      //         text: res.data.message,
      //     }
      // );
    }
  }

  async allUsers() {
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.USERS_ENDPOINT, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("GET ALL USERS ERROR");
        console.log(err);
      });

    if (res && res.status == 200) {
      return res.data;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Não foi possível listar os usuários",
      });
    }
  }

  async getUserId(id) {
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.USERS_ENDPOINT + `${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("GET USER ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível encontrar o usuário",
        });
      });

    let resOk =
      "status_code" in res.data
        ? (resOk = res.data["status_code"] === 200)
        : true;
    if (res && res.status == 200 && resOk) {
      return res.data;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Não foi possível encontrar o usuário",
      });
    }
  }

  async createUser(user) {
    await new AuthService().refreshToken();
    let res = await axios
      .post(Constants.BASE_URL + this.USERS_ENDPOINT, JSON.stringify(user), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("CREATE USERS ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Todos os campos devem estar preenchidos",
        });
      });
    await this.createPassword(res.data.id, user.password);
  }

  async createUserAnon(user) {
    await axios
      .post(Constants.BASE_URL + this.USERS_ENDPOINT, JSON.stringify(user), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .catch((err) => {
        console.log("CREATE USERS ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Todos os campos devem estar preenchidos",
        });
      });

    router.push("/login");
  }

  async createPassword(id, senha, re_senha) {
    await new AuthService().refreshToken();
    await axios
      .post(
        Constants.BASE_URL + this.USERS_ENDPOINT + id + "/change-password/",
        JSON.stringify({ password: senha, re_password: re_senha }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("access")}`,
          },
        }
      )
      .catch((err) => {
        console.log("CREATE USERS ERROR");
        console.log(err);
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "A alteração foi salva! Mas alertamos que você está utilizando uma senha fraca!",
        });
      });

    router.push("/users");
  }

  async editUser(user) {
    await new AuthService().refreshToken();
    await axios
      .patch(Constants.BASE_URL + this.USERS_ENDPOINT + `${user.id}/`, user, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("EDIT USER ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Os campos não podem estar em branco",
        });
      });
  }

  async deleteUser(id) {
    await new AuthService().refreshToken();
    await axios
      .delete(Constants.BASE_URL + this.USERS_ENDPOINT + `${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("DELETE USER ACCOUNT ERROR");
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possível deletar o usuário",
        });
      });
    window.location.reload(false);
  }
}
