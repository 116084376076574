<template>
  <div
    class="background"
    style="
      width: 100%;
      margin: auto;
      margin-top: 15px;
      margin-bottom: 15px;
      overflow-y: scroll;
      height: 75vh;
    "
  >
    <div>
      <v-list>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
          no-action
          class="text-h6"
          :value="item.name == 'Padrão'"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-group no-action sub-group @click="organograma('organograma')">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Organograma 2024</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-group>

          <v-list-group
            :value="true"
            no-action
            sub-group
            v-for="value in item.subdepartments"
            :key="value.name"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ value.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-group>

          <v-list-group
            :value="true"
            no-action
            sub-group
            v-if="item.name == 'Painel Gestores'"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <v-list-item-title>Indicadores Gestão</v-list-item-title>
                  <v-list-item-icon
                    style="margin-top: 0px; margin-bottom: 0px"
                    v-b-modal.modal-lg
                  >
                    <v-icon v-text="'mdi-pencil-box-outline'"></v-icon>
                  </v-list-item-icon>
                </div>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="item in indicators"
              :key="item.title"
              link
              style="padding-left: 50px"
              @click="abrirArea('indicadores', item.id)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <v-list-item-title>BI's</v-list-item-title>
                  <v-list-item-icon
                    style="margin-top: 0px; margin-bottom: 0px"
                    v-b-modal.modal-bi
                  >
                    <v-icon v-text="'mdi-pencil-box-outline'"></v-icon>
                  </v-list-item-icon>
                </div>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.bis"
              :key="child.title"
              link
              style="padding-left: 50px"
              @click="abrirArea('area', item.id)"
            >
              <v-list-item-content style="cursor: pointer" class="item">
                <v-list-item-title v-text="child.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group
            :value="true"
            no-action
            sub-group
            v-if="item.name == 'Comercial'"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <v-list-item-title>Campanhas</v-list-item-title>
                  <v-list-item-icon
                    style="margin-top: 0px; margin-bottom: 0px"
                    v-b-modal.modal-campanha
                  >
                    <v-icon v-text="'mdi-pencil-box-outline'"></v-icon>
                  </v-list-item-icon>
                </div>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(campanha, index) in campaigns"
              :key="campanha"
              link
              style="padding-left: 50px"
              @click="abrirCampanha('campanha', index)"
            >
              <v-list-item-content style="cursor: pointer" class="item">
                <v-list-item-title v-text="campanha.key"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>
    </div>

    <!-- Início do modal -->
    <b-modal
      id="modal-campanha"
      size="lg"
      title="Adicionar Indicador"
      style="color: rgb(161, 0, 12) !important"
    >
      <form enctype="multipart/form-data" action="#" method="post">
        <div class="row">
          <div class="col-md-4 mb-3">
            <div role="group">
              <label for="input-name">Nome da camapanha</label>
              <b-form-input
                id="input-name"
                v-model="campanha.title"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Indicador Comercial"
                trim
              ></b-form-input>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="inputState">Selecione o arquivo</label>
            <input
              class="form-control"
              @change="onFileChange"
              type="file"
              id="formFile"
            />
          </div>

          <!-- input de Campanha -->
          <div class="col-md-4 mb-3">
            <label for="inputState">Qual a campanha?</label>
            <select
              id="inputState"
              v-model="campanha.campaign_type"
              class="form-control"
              aria-placeholder="Selecione um tipo de campanha"
            >
              <option
                v-for="item in type_campanha"
                :key="item"
                :value="type_campanha.indexOf(item, 0) + 1"
              >
                {{ item }}
              </option>
            </select>
          </div>

          <!-- input de Mês -->
          <div class="col-md-4 mb-3">
            <label for="inputState">Mês</label>
            <select
              id="inputState"
              v-model="campanha.month"
              class="form-control"
            >
              <option
                v-for="mes in meses"
                :key="mes"
                :value="meses.indexOf(mes, 0) + 1"
              >
                {{ mes }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="mg" variant="secondary" @click="cancel()">
          Cancelar
        </b-button>
        <b-button size="mg" variant="danger" @click="createCampaign">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <!-- Início do modal -->
    <b-modal
      id="modal-lg"
      size="lg"
      title="Adicionar Indicador"
      style="color: rgb(161, 0, 12) !important"
    >
      <form enctype="multipart/form-data" action="#" method="post">
        <div class="row">
          <div class="col-md-4 mb-3">
            <div role="group">
              <label for="input-name">Nome</label>
              <b-form-input
                id="input-name"
                v-model="app.title"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Indicador Comercial"
                trim
              ></b-form-input>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="inputState">Selecione o arquivo</label>
            <input
              class="form-control"
              @change="onFileChange"
              type="file"
              id="formFile"
            />
          </div>

          <!-- input de Setor -->
          <div class="col-md-4 mb-3">
            <label for="inputState">Setor</label>
            <select
              id="inputState"
              v-model="app.department"
              class="form-control"
            >
              <option selected>Escolha...</option>
              <option
                v-for="(department, i) in departments"
                :key="i"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="mg" variant="secondary" @click="cancel()">
          Cancelar
        </b-button>
        <b-button size="mg" variant="danger" @click="createIndicator">
          Salvar
        </b-button>
      </template>
    </b-modal>
    <!-- Início do modal -->
    <b-modal
      id="modal-bi"
      size="lg"
      title="Adicionar BI"
      style="color: rgb(161, 0, 12) !important"
    >
      <form enctype="multipart/form-data" action="#" method="post">
        <div class="row">
          <div class="col-md-4 mb-3">
            <div role="group">
              <label for="input-name">Nome</label>
              <b-form-input
                id="input-name"
                v-model="bi.name"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Nome do BI"
                trim
              ></b-form-input>
            </div>
          </div>
          <!-- input de URL -->
          <div class="col-md-4 mb-3">
            <label for="input-url">URL</label>
            <b-form-input
              type="url"
              id="input-url"
              v-model="bi.link"
              placeholder="https://example.com.br"
            ></b-form-input>
          </div>

          <!-- input de Setor -->
          <div class="col-md-4 mb-3">
            <label for="inputState">Setor</label>
            <select
              multiple
              id="inputState"
              v-model="bi.departments"
              class="form-control"
            >
              <option selected>Escolha...</option>
              <option
                v-for="(department, i) in departments"
                :key="i"
                :value="department.id"
              >
                {{ department.name }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="mg" variant="secondary" @click="cancel()">
          Cancelar
        </b-button>
        <b-button size="mg" variant="danger" @click="createBI">
          Salvar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AreaService from "../../services/AreaService/AreaService";
import CampaignsService from "../../services/CampaignsService/CampaignsService";
import IndicatorService from "../../services/IndicadorService/IndicadorService";
import DepartmentService from "../../services/DepartmentService/DepartmentService";
import { router } from "../../routers/router";

// import AuthService from "../../services/AuthService/AuthService";
// import AuthModel from "../../models/Auth/AuthModel";

export default {
  props: {
    user: {
      required: true,
    },
  },
  name: "NavItem",
  data() {
    return {
      indicatorService: null,
      campaignService: null,
      departments: [],
      items: [],
      campaigns: [],
      indicators: [],
      meses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      app: {
        title: "",
        link: "",
        file: "",
        department: [],
      },
      type_campanha: [
        "SuperGreen",
        "Acelera 2023",
        "Quadro de Medalhas",
        "Performance Comercial",
        "Resultado Comercial",
      ],
      campanha: {
        title: "",
        link: "",
        file: "",
        department: 5,
        month: "",
        year: "2023",
        campaign_type: "",
      },
      bi: {
        name: "",
        link: "",
        departments: [],
        icon: ".",
      },
    };
  },
  created() {
    this.indicatorService = new IndicatorService();
    this.campaignService = new CampaignsService();
    this.getBis();
    this.getCampaigns();
    this.getIndicators();
  },
  methods: {
    async createIndicator() {
      await this.indicatorService.createIndicator(this.app);
    },
    async createBI() {
      await this.indicatorService.createBI(this.bi);
    },
    async createCampaign() {
      this.departments = await new DepartmentService().allDepartments();
      this.departments = this.departments.filter(
        (element) => element.name != "Comercial"
      );
      this.campanha.department = this.departments[0].id;
      await this.campaignService.createCampaign(this.campanha);
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.app.file = files[0];
      this.campanha.file = files[0];
    },

    abrirArea(page, id) {
      router.push(`/${page}/${id}`);
      // window.location.reload(true)
    },

    organograma(page) {
      router.push(`/${page}/1`);
    },

    abrirCampanha(page, id) {
      router.push(`/${page}/${id}`);
      // window.location.reload(true)
    },

    async getCampaigns() {
      let result = await new CampaignsService().allCampaigns();
      let campanhas = [];
      Object.keys(result).forEach((key) => {
        campanhas.push({ key, value: result[key] });
      });
      delete campanhas[0];
      this.campaigns = { ...campanhas };
    },

    async getIndicators() {
      this.indicators = await new IndicatorService().allIndicators();
    },

    async getBis() {
      const bis = await new AreaService().allBis();

      this.departments = await new DepartmentService().allDepartments();

      for (let i = 0; i < this.departments.length; i++) {
        this.departments[i].bis = bis.filter((v) =>
          v.departments.includes(this.departments[i].id)
        );
      }
      if (this.user.account_type > 0) {
        this.departments = this.departments.filter(
          (element) => element.name != "Diretoria"
        );
      }

      if (this.user.account_type > 1) {
        this.departments = this.departments.filter(
          (element) => element.name != "Painel Gestores"
        );
      }
      // this.items = this.departments
      console.log(JSON.parse(JSON.stringify(this.departments)));
      this.items = this.departments.filter((element) => element.area != null); // pra ocultar aquele Padrão que nao tem área (nao é usado)
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400&family=Poppins:wght@100;200;300;500;600;700;800;900&display=swap");
.text-h6 {
  color: brown;
}

.material-icons {
  margin-right: 20px;
}

.icon {
  font-size: 18px;
}

.item {
  margin-left: 10% !important;
}
.v-list-item__content {
  margin-left: 10%;
}

.item:hover {
  color: brown;
  background-color: rgb(245, 223, 223);
}
</style>
