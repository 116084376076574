import axios from "axios";
import Constants from "../../core/constants/Constants";
// import AuthService from "../AuthService/AuthService";
import { getCookie } from "../../utils/utils";
// import Swal from "sweetalert2";
// import { router } from '../../routers/router'

/*
Department Service
*/
export default class DepartmentService {
  DEPARTMENTS_ENDPOINT = "/api/departments/";

  async allDepartments() {
    // await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.DEPARTMENTS_ENDPOINT, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log("GET ALL DEPARTMENTS ERROR");
        console.log(err);
      });

    if (res && res.status == 200) {
      return res.data;
    } else {
      // Swal.fire(
      //     {
      //         icon: 'error',
      //         title: 'Oops...',
      //         text: res.data.message,
      //     }
      // );
    }
  }
}
